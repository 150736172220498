<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<!-- Activar Formulario -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				fab
				x-small
				color="primary"
				dark
				v-bind="attrs"
				v-on="on"
			>
				<v-icon>mdi-account-plus-outline</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Agregar usuario</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn dark text @click="saveUserForm()"> Guardar </v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-row>
					<v-col cols="6">
						<form>
							<v-text-field
								v-model="user.name"
								label="Nombre"
								type="text"
								required
							></v-text-field>
							<v-text-field
								v-model="user.email"
								label="Email"
								type="email"
								required
							></v-text-field>
							<v-text-field
								v-model="user.password"
								label="Contraseña"
								type="password"
								required
							></v-text-field>
							<v-text-field
								v-model="user.telephone"
								label="Teléfono"
								type="text"
								required
							></v-text-field>
							<v-select
								:items="associationAreas"
								v-model="user.association_area"
								item-text="name"
								item-value="code"
								label="Area"
								required
							></v-select>

							<template
								v-if="user.association_area == 'watchman'"
							>
								<h2>Regantes asignados</h2>
								<div>
									<v-chip
										v-for="(
											item, index
										) in irrigatorsToSend"
										:key="index"
										class="ma-2 primary"
										close
										@click:close="
											unselectIrrigator(item.code)
										"
										text-color="white"
									>
										<v-avatar left class="blue darken-4">
											{{ 1 + index }}
										</v-avatar>
										{{ item.name }} /
										{{ item.code }}
									</v-chip>
								</div>
							</template>
						</form>
					</v-col>

					<v-col cols="6">
						<template v-if="user.association_area == 'watchman'">
							<div class="text-h5 pt-4">Asignar regantes</div>
							<IrrigatorListFilter />

							<template
								v-if="
									channelSelected.code && countChannelSelected
								"
							>
								<v-btn
									@click="
										unselectFromChannel(
											channelSelected.code
										)
									"
									color="primary"
									outlined
									>Desmarcar
									{{ countChannelSelected }}
									regantes</v-btn
								>
								Para desmarcar todos los regantes de
								{{ channelSelected.name }} pulse el botón
							</template>

							<template v-else-if="channelSelected.code">
								<v-btn
									@click="
										selectFromChannel(channelSelected.code)
									"
									color="primary"
									>Agregar
									{{ irrigatorsLastVisible.count }}
									regantes</v-btn
								>
								Para agregar todos los regantes de
								{{ channelSelected.name }} pulse el botón
							</template>

							<IrrigatorListShortWithCheckbox
								ref="irrigators-list"
								@irrigatorsSelected="irrigatorsSelected"
							/>
						</template>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import IrrigatorListFilter from '@/components/IrrigatorListFilter.vue';
	import IrrigatorListShortWithCheckbox from '@/components/IrrigatorListShortWithCheckbox.vue';
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'user-form-add',

		data() {
			return {
				dialog: false,

				user: {
					name: null,
					email: null,
					password: null,
					telephone: null,
					association_area: null,
				},

				irrigatorsToSend: [],
			};
		},

		watch: {
			user: {
				handler(newValue) {
					if (newValue.association_area != 'watchman') {
						delete newValue.channel;
						this.irrigatorsToSend = [];
					}
				},
				deep: true,
			},

			dialog: {
				handler() {
					this.user = {
						name: null,
						email: null,
						password: null,
						telephone: null,
						association_area: null,
					};

					this.irrigatorsToSend = [];
				},
			},
		},

		components: {
			IrrigatorListFilter,
			IrrigatorListShortWithCheckbox,
		},

		computed: {
			...mapState('userControl', ['associationAreas']),
			...mapState('irrigatorControl', [
				'channels',
				'irrigators',
				'irrigatorsLastVisible',
				'filterOptions',
			]),

			toSend() {
				let user = this.$utils.deepCopy(this.user);

				const role = (acc, curr) =>
					(acc =
						curr.code == user.association_area ? curr.code : acc);
				user.role = this.associationAreas.reduce(role, '');

				const area = (acc, curr) =>
					(acc = curr.code == user.association_area ? curr.id : acc);
				user.association_area = this.associationAreas.reduce(area, '');

				if (!user.channel) {
					/* Elimina la propiedad vacia */
					delete user.channel;
				}

				if (this.irrigatorsToSend.length) {
					user.assigned_irrigators = this.irrigatorsToSend;
				}

				return user;
			},

			channelSelected() {
				return this.channels.reduce(
					(acc, curr) =>
						(acc =
							curr.code == this.filterOptions.channel
								? curr
								: acc),
					{}
				);
			},

			countChannelSelected() {
				return this.irrigatorsToSend.filter(
					item => item.channel == this.channelSelected.code
				).length;
			},
		},

		methods: {
			...mapActions('userControl', ['saveUser']),

			saveUserForm() {
				this.saveUser(this.toSend).then(() => {
					this.dialog = false;
					this.user = {
						name: null,
						email: null,
						telephone: null,
						association_area: null,
					};
				});
			},

			close() {
				this.dialog = false;
			},

			irrigatorsSelected(data) {
				this.irrigatorsToSend = data;
			},

			unselectIrrigator(data) {
				this.$refs['irrigators-list'].unselectIrrigator(data);
			},
		},
	};
</script>
