<template>
	<v-container grid-list-xs>
		<v-row>
			<v-col class="text-center">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="index"
									class="text-left"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(row, indexRow) in watchmanList"
								:key="`body-${indexRow}`"
							>
								<td
									v-for="(col, indexCol) in headers"
									:key="`${indexRow}-col-${indexCol}`"
								>
									<v-checkbox
										v-if="col.value == 'item_number'"
										v-model="watchmansSelected"
										:value="row['user_id']"
										:disabled="itIsAllowed(row['user_id'])"
									></v-checkbox>

									<span
										v-else
										:class="
											itIsAllowed(row['user_id'])
												? 'text-decoration-line-through'
												: ''
										"
									>
										{{ row[col.value] }}
									</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapState, mapActions } from 'vuex';

	export default {
		name: 'users-list-short-with-checkbox',

		props: {
			assigned: {
				type: Array,
			},
		},

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Nombre', value: 'name' },
					{ text: 'Teléfono', value: 'telephone' },
				],

				watchmansSelected: [],
			};
		},

		watch: {
			watchmansSelected(newValue) {
				this.$emit('watchmansSelected', newValue);
			},
		},

		computed: {
			...mapState('userControl', ['watchmans']),

			watchmanList() {
				return this.watchmans.map(item => {
					item.user_id = item.user.id;

					return item;
				});
			},
		},

		mounted() {
			this.loadWatchmanList();
		},

		methods: {
			...mapActions('userControl', ['loadWatchmanList']),

			/* Verificar si es un codigo permitido para marcar y ser guardado */
			itIsAllowed(id) {
				return this.assigned ? this.assigned.includes(id) : false;
			},

			unselectWatchman(data) {
				this.watchmansSelected = this.watchmansSelected.filter(
					item => item.code != data
				);
			},

			loadWatchmanSelected(data) {
				this.watchmansSelected = data;
			},
		},
	};
</script>
