<template>
	<div>
		<h2 class="text-h5">Regantes asignados: {{ totalIrrigators }}</h2>
		<div
			v-for="channel in channels"
			:key="`channel-c${channel.code}`"
			class="mt-3"
		>
			<div class="text-subtitle-1">
				{{ channel.name }} | Código: {{ channel.code }}
			</div>
			<v-chip
				v-for="(item, index) in channel.irrigators"
				:key="index"
				class="mr-2 mb-2 primary"
				close
				@click:close="unselectIrrigator(item.code)"
				text-color="white"
			>
				<v-avatar left class="blue darken-4">
					{{ 1 + index }}
				</v-avatar>
				{{ item.name }} /
				{{ item.code }}
			</v-chip>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'watchman-codes-assigned',

		props: {
			irrigators: {
				type: Array,
			},
		},

		computed: {
			channels() {
				let channel_code = this.irrigators.map(item => item.channel);
				/* Eliminar codigos duplicados */
				channel_code = [...new Set(channel_code)];

				channel_code = channel_code.map(item => ({
					code: item,
					name: this.irrigators.find(c => c.channel == item)
						.channel_name,
					irrigators: this.irrigators
						.filter(c => c.channel == item)
						.map(i => ({ code: i.code, name: i.name })),
				}));

				return channel_code;
			},

			totalIrrigators() {
				return this.irrigators.length || 0;
			},
		},

		methods: {
			unselectIrrigator(code) {
				this.$emit('unselect', code);
			},
		},
	};
</script>
