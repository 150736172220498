<template>
	<v-container grid-list-xs>
		<v-row>
			<v-col class="text-center">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="index"
									class="text-left"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in showUsers" :key="index">
								<td
									v-for="(field, index) in headers"
									:key="`${index}-tbody`"
									class="text-left"
								>
									<template v-if="item[field.value]">
										<template
											v-if="field.value == 'blocked'"
										>
											<v-icon>
												{{ item[field.value] }}
											</v-icon>
										</template>

										<template
											v-else-if="field.text == 'Eliminar'"
										>
											<v-btn
												icon
												@click="deleteUser(item.id)"
												:disabled="item.id == user.id"
												><v-icon>
													mdi-delete-outline
												</v-icon></v-btn
											>
										</template>

										<template
											v-else-if="field.text == 'Nombre'"
										>
											<a @click="readUser(item.id)">{{
												item[field.value]
											}}</a>
										</template>

										<template v-else>
											{{ item[field.value] }}
										</template>
									</template>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="text-center">
				<UsersListPagination />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import UsersListPagination from '@/components/UsersListPagination';

	export default {
		name: 'users-list',
		data() {
			return {
				headers: [
					{ text: 'Nombre', value: 'name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Teléfono', value: 'telephone' },
					{ text: 'Area', value: 'area' },
					{ text: 'Activado', value: 'blocked' },
					{ text: 'Eliminar', value: 'id' },
				],
			};
		},

		components: {
			UsersListPagination,
		},

		computed: {
			...mapState('userControl', ['users', 'user', 'associationAreas']),

			showUsers() {
				const users = this.$utils.deepCopy(this.users),
					list = users.map(u => {
						u.area = this.associationAreas.reduce(
							(acc, curr) =>
								u.association_area &&
								curr.id == u.association_area.id
									? curr.name
									: acc,
							''
						);

						u.email = u.user && u.user.email ? u.user.email : '';

						u.blocked = u.blocked
							? 'mdi-account-cancel-outline'
							: 'mdi-account-check-outline';
						return u;
					});

				return list;
			},
		},

		mounted() {
			this.clearUsers();
			this.loadUsers({ count: 0, next: 0 });
		},

		methods: {
			...mapActions('userControl', [
				'clearUsers',
				'loadUsers',
				'readUser',
				'deleteUser',
			]),
		},
	};
</script>
