<template>
	<div>
		<h2 class="text-h4 mb-6">Lista de usuarios de ACM <UserFormAdd /></h2>

		<UsersList />

		<UserFormUpdate />
	</div>
</template>

<script>
	import UsersList from '@/components/UsersList';
	import UserFormAdd from '@/components/UserFormAdd';
	import UserFormUpdate from '@/components/UserFormUpdate';

	export default {
		name: 'user-page',
		components: {
			UsersList,
			UserFormAdd,
			UserFormUpdate,
		},
	};
</script>
