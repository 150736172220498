<template>
	<v-row>
		<v-col>
			<v-text-field
				v-model="code"
				dense
				solo
				label="Código/Rut/Nombre regante"
				hide-details="auto"
				@input="changeOptionCode"
			></v-text-field>
		</v-col>

		<v-col v-if="!hide_slow_payer">
			<v-select
				v-model="slow_payer"
				dense
				solo
				:items="slow_payer_options"
				item-text="name"
				item-value="code"
				label="Por estado de deuda"
				hide-details="auto"
				@change="changeOption"
			></v-select>
		</v-col>

		<v-col v-if="!hide_watchman">
			<v-select
				v-model="watchman"
				dense
				solo
				:items="showWatchman"
				item-text="name"
				item-value="id"
				label="Celador"
				hide-details="auto"
				@change="changeOption"
			></v-select>
		</v-col>

		<v-col>
			<v-autocomplete
				v-model="channel"
				:items="showLChannels"
				item-value="code"
				item-text="name"
				dense
				solo
				label="Canal"
				@change="changeOption"
			></v-autocomplete>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'irrigator-list-filter',

		props: {
			hide_slow_payer: {
				type: Boolean,
			},
			hide_watchman: {
				type: Boolean,
			},
			not_reset: {
				type: Boolean,
			},
		},

		data() {
			return {
				watchman: null,
				channel: null,
				slow_payer: null,
				code: '',

				slow_payer_options: [
					{
						code: null,
						name: 'Todos',
					},
					{
						code: 'true',
						name: 'Con deuda',
					},
					{
						code: 'false',
						name: 'Sin deuda',
					},
				],
			};
		},

		computed: {
			...mapState({
				watchmans: state => state.userControl.watchmans,
				channels: state => state.irrigatorControl.channels,
				filter: state => state.irrigatorControl.filterOptions,

				user: state => state.userControl.user,
			}),

			filterToSend() {
				return {
					watchman: this.watchman,
					channel: this.channel,
					code: this.code,
					slow_payer: this.slow_payer,
				};
			},

			showLChannels() {
				let list = this.$utils.deepCopy(this.channels);

				list.unshift({ code: null, name: 'Todos los canales' });

				return list;
			},

			showWatchman() {
				let list = this.watchmans;

				list.unshift({ id: null, name: 'Todas los celadores' });

				return list;
			},
		},

		destroyed() {
			this.clearIrrigators();
		},

		mounted() {
			if (!this.not_reset) this.resetFilterOptions();
			this.loadLocations();
			this.loadWatchmanList();
		},

		methods: {
			...mapActions('irrigatorControl', [
				'loadLocations',
				'updateFilterOptions',
				'resetFilterOptions',
				'clearIrrigators',
			]),
			...mapActions('userControl', ['loadWatchmanList']),

			changeOption() {
				this.updateFilterOptions(this.filterToSend);
			},

			changeOptionCode() {
				if (this.code.length == 0 || this.code.length >= 3)
					this.updateFilterOptions(this.filterToSend);
			},
		},
	};
</script>
