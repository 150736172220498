<template>
	<div>
		<v-row>
			<v-col>
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="`head-${index}`"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(row, indexRow) in irrigatorsShow"
								:key="`body-${indexRow}`"
							>
								<td
									v-for="(col, indexCol) in headers"
									:key="`${indexRow}-col-${indexCol}`"
								>
									<v-checkbox
										v-if="col.value == 'item_number'"
										v-model="irrigatorsSelected"
										:value="row['fulldata']"
										:disabled="itIsAllowed(row['code'])"
									></v-checkbox>

									<span
										v-else
										:class="
											itIsAllowed(row['code'])
												? 'text-decoration-line-through'
												: ''
										"
									>
										{{ row[col.value] }}
									</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="text-center">
				<IrrigatorListPagination />
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import IrrigatorListPagination from '@/components/IrrigatorListPagination';

	export default {
		name: 'irrigators-list-short-with-checkbox',

		props: {
			assigned: {
				type: Array,
			},
		},

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Código', value: 'code' },
					{ text: 'Nombre', value: 'name' },
					{ text: 'Canal', value: 'channel_name' },
					{ text: 'Celador', value: 'watchman_name' },
				],

				irrigatorsSelected: [],
			};
		},

		watch: {
			irrigatorsSelected(newValue) {
				this.$emit('irrigatorsSelected', newValue);
			},

			irrigatorsShow(newValue) {
				const irr = this.$utils.deepCopy(this.irrigatorsSelected);

				/* Si viene valores de regantes, refresco el arreglo para que carguen bien los checkbox con los valores ya existentes */
				if (newValue.length) {
					this.irrigatorsSelected = [];
					this.irrigatorsSelected = irr;
				}
			},
		},

		components: {
			IrrigatorListPagination,
		},

		computed: {
			...mapState({
				irrigators: state => state.irrigatorControl.irrigators,
				channels: state => state.irrigatorControl.channels,
			}),

			irrigatorsShow() {
				let irrigators = this.irrigators.map((irr, index) => {
					const show = {};
					show.item_number = index + 1;

					show.channel_name = irr.channel.name;

					show.watchman_name = irr.watchman ? irr.watchman.name : '';

					show.name = irr.name;
					show.code = irr.code;

					show.fulldata = {
						code: irr.code,
						name: irr.name,
						channel: irr.channel.code,
						channel_name: show.channel_name,
					};

					return show;
				});

				return irrigators.length ? irrigators : [];
			},
		},

		methods: {
			unselectIrrigator(data) {
				this.irrigatorsSelected = this.irrigatorsSelected.filter(
					item => item.code != data
				);
			},

			loadIrrigatorSelected(data) {
				this.irrigatorsSelected = data;
			},

			/* Verificar si es un codigo permitido para marcar y ser guardado */
			itIsAllowed(code) {
				return this.assigned ? this.assigned.includes(code) : false;
			},
		},
	};
</script>
