<template>
	<v-row v-if="users.length">
		<v-col>
			<v-btn class="primary" :disabled="noMore" @click="loadMore">{{
				buttonText
			}}</v-btn>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'users-list-pagination',

		data() {
			return {
				perPage: Number(process.env.VUE_APP_IRRIGATORS_PER_PAGE),
			};
		},

		computed: {
			...mapState({
				users: state => state.userControl.users,
				noMore: state => state.userControl.noMoreUsers,
				lastVisible: state => state.userControl.usersLastVisible,
			}),

			buttonText() {
				return this.noMore
					? 'Fin de la lista'
					: `Cargar ${this.perPage} más de ${this.lastVisible.count}`;
			},
		},

		methods: {
			...mapActions('userControl', ['loadUsers']),

			loadMore() {
				this.loadUsers(this.lastVisible);
			},
		},
	};
</script>
