<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card>
			<v-toolbar dark color="deep-orange darken-1">
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Actualizar usuario</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-title v-if="alertSaving"
					>Esto puede demorar 1 minuto o más según número de regantes.
					<v-progress-circular
						:size="20"
						:width="3"
						color="white"
						indeterminate
					></v-progress-circular>
				</v-toolbar-title>
				<v-toolbar-items v-if="!alertSaving">
					<v-btn dark text @click="saveUserForm()">Guardar</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-row>
					<v-col cols="6">
						<form>
							<v-text-field
								v-model="user.name"
								label="Nombre"
								type="text"
								required
							></v-text-field>
							<v-text-field
								v-model="user.email"
								label="Email"
								type="email"
								required
							></v-text-field>
							<v-text-field
								v-model="user.password"
								label="Contraseña"
								type="password"
								required
							></v-text-field>
							<v-text-field
								v-model="user.telephone"
								label="Teléfono"
								type="text"
								required
							></v-text-field>
							<v-select
								:items="associationAreas"
								v-model="user.association_area"
								item-text="name"
								item-value="code"
								label="Area"
								required
							></v-select>

							<v-switch
								v-model="user.active_audio"
								label="Activar envío de audio"
							></v-switch>

							<!-- Lista de regantes asignados -->
							<WatchmanCodesAssigned
								v-if="user.association_area == 'watchman'"
								:irrigators="irrigatorsToSend"
								@unselect="unselectIrrigator"
							/>
						</form>
					</v-col>

					<v-col cols="6">
						<template v-if="user.association_area == 'watchman'">
							<div class="text-h5 pt-4">Asignar regantes</div>

							<IrrigatorListFilter
								hide_slow_payer
								hide_watchman
							/>

							<template
								v-if="
									channelSelected.code && countChannelSelected
								"
							>
								<v-btn
									@click="
										unselectFromChannel(
											channelSelected.code
										)
									"
									color="primary"
									outlined
									>Desmarcar
									{{ countChannelSelected }}
									regantes</v-btn
								>
								<span class="ml-2"
									>Para desmarcar todos los regantes de
									{{ channelSelected.name }} pulse el
									botón</span
								>
							</template>

							<template v-else-if="activeButtonSelectIrrigators">
								<v-btn
									@click="
										selectFromChannel(channelSelected.code)
									"
									color="primary"
									:disabled="desactiveSelectAllFromChannel"
									>Agregar
									{{ irrigatorsLastVisible.count }}
									regantes</v-btn
								>
								<span class="ml-2">
									<template
										v-if="desactiveSelectAllFromChannel"
									>
										Hay regantes que han sido asignado a
										otro(s) celador(es). No se pueden
										escoger todos.
									</template>
									<template v-else
										>Para agregar todos los regantes de
										{{ channelSelected.name }} pulse el
										botón</template
									>
								</span>
							</template>

							<IrrigatorListShortWithCheckbox
								ref="irrigator-list"
								@irrigatorsSelected="irrigatorsSelected"
								:assigned="irrigatorsAssignedToCompare"
							/>
						</template>

						<template v-if="user.association_area == 'sectionm'">
							<UsersListShortWithCheckbox
								ref="watchman-list"
								@watchmansSelected="watchmansSelected"
								:assigned="watchmansAssignedToCompare"
							/>
						</template>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import IrrigatorListFilter from '@/components/IrrigatorListFilter';
	import IrrigatorListShortWithCheckbox from '@/components/IrrigatorListShortWithCheckbox';
	import WatchmanCodesAssigned from '@/components/WatchmanCodesAssigned';
	import UsersListShortWithCheckbox from '@/components/UsersListShortWithCheckbox';

	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'user-form-update',

		data() {
			return {
				dialog: false,

				user: {
					name: null,
					email: null,
					password: null,
					telephone: null,
					association_area: null,
					active_audio: false,
				},

				alertSaving: false,

				irrigatorsToSend: [],
				/* Regantes asignados a los demas celadores */
				irrigatorsAssignedToCompare: [],

				watchmansToSend: [],
				/* Celadores asignados a los demas jefes de sección */
				watchmansAssignedToCompare: [],
			};
		},

		watch: {
			selectedUser: {
				handler(newValue) {
					if (newValue.name) {
						this.dialog = newValue.name ? true : false;
						console.log(
							'newValue',
							newValue,
							newValue?.app_setting
						);
						this.user = {
							name: newValue.name,
							email: newValue.user.email,
							telephone: newValue.telephone,
							association_area: newValue.association_area.code,
							created_at: newValue.createdAt,
							active_audio:
								newValue.app_setting &&
								newValue.app_setting.active_audio
									? newValue.app_setting.active_audio
									: false,
						};

						if (newValue.association_area.code == 'watchman') {
							this.irrigatorsToSend =
								newValue.assigned_irrigators == null
									? []
									: newValue.assigned_irrigators;

							setTimeout(
								() =>
									this.loadIrrigatorsSelected(
										newValue.assigned_irrigators
									),
								10
							);

							/* Busca los regantes que ya cuentan con un celador para desactivarlos de la lista */
							this.irrigatorsAssigned().then(res => {
								/* Regantes asignados */
								this.irrigatorsAssignedToCompare = res;

								/* Borrar de los regantes con celador, a los que pertenecer al celador elegido para que no se bloqueen los regantes de el mismo por si se desea desmarcar alguno y volverlo a marcar */
								if (
									newValue.assigned_irrigators &&
									newValue.assigned_irrigators.length > 0
								) {
									newValue.assigned_irrigators.forEach(
										irr => {
											/* Regantes asignados a los demas celadores */
											this.irrigatorsAssignedToCompare =
												this.irrigatorsAssignedToCompare.filter(
													comp => comp != irr.code
												);
										}
									);
								}
							});
						}
					} else {
						this.user = {
							name: null,
							email: null,
							telephone: null,
							association_area: null,
							active_audio: false,
						};

						this.watchmansToSend = [];
						/* Celadores asignados a los demas jefes de sección */
						this.watchmansAssignedToCompare = [];
					}
				},
				deep: true,
			},

			user: {
				handler(newvalue) {
					if (newvalue.association_area != 'watchman')
						delete newvalue.channels;
				},
				deep: true,
			},

			sectionm: {
				handler(newValue) {
					if (this.selectedUser.association_area.code == 'sectionm') {
						/* Seteo los celadores a enviar */
						this.watchmansToSend =
							this.selectedUser.assigned_users == null
								? []
								: this.selectedUser.assigned_users;

						setTimeout(
							() =>
								this.loadWatchmanSelected(
									this.selectedUser.assigned_users
								),
							10
						);

						newValue.forEach(item => {
							let ids = item.assigned_users || [];

							/* Ignoro al usuario actual de la lista para solo bloquear los regantes de los otros */
							if (item.id != this.selectedUser.id && ids.length)
								this.watchmansAssignedToCompare =
									this.watchmansAssignedToCompare.concat(ids);
						});
					}
				},
				deep: true,
			},
		},

		components: {
			IrrigatorListFilter,
			IrrigatorListShortWithCheckbox,
			UsersListShortWithCheckbox,
			WatchmanCodesAssigned,
		},

		computed: {
			...mapState('userControl', [
				'associationAreas',
				'selectedUser',
				'sectionm',
			]),
			...mapState('irrigatorControl', [
				'channels',
				'irrigators',
				'filterOptions',
				'irrigatorsLastVisible',
			]),

			toSend() {
				let user = this.$utils.deepCopy(this.user);

				const role = (acc, curr) =>
					(acc =
						curr.code == user.association_area ? curr.code : acc);
				user.role = this.associationAreas.reduce(role, '');

				user.association_area = this.associationAreas.reduce(
					(acc, curr) =>
						(acc =
							curr.code == user.association_area ? curr.id : acc),
					''
				);

				/* Usuario de la asociacion */
				user.id = this.selectedUser.id;
				/* Usuario del sistema */
				user.user_id = this.selectedUser.user.id;

				if (!user.channel) {
					/* Elimina la propiedad vacia */
					delete user.channel;
				}

				if (this.irrigatorsToSend.length) {
					user.assigned_irrigators = this.irrigatorsToSend;
				} else {
					user.assigned_irrigators = [];
				}

				if (this.watchmansToSend.length) {
					user.assigned_users = this.watchmansToSend;
				} else {
					user.assigned_users = [];
				}

				return user;
			},

			channelSelected() {
				return this.channels.reduce(
					(acc, curr) =>
						(acc =
							curr.code == this.filterOptions.channel
								? curr
								: acc),
					{}
				);
			},

			countChannelSelected() {
				return this.irrigatorsToSend.filter(
					item => item.channel == this.channelSelected.code
				).length;
			},

			/* Códigos de canalas de los demas celadores que cuentan con regantes */
			channelsAssigned() {
				let channels = this.irrigatorsAssignedToCompare.map(irr =>
					String(Number(irr.split('-', 1)[0]))
				);

				return channels;
			},

			desactiveSelectAllFromChannel() {
				let code = String(Number(this.channelSelected.code));

				return code ? this.channelsAssigned.includes(code) : false;
			},

			activeButtonSelectIrrigators() {
				return (
					this.channelSelected.code &&
					!this.filterOptions.code &&
					!this.filterOptions.location
				);
			},
		},

		methods: {
			...mapActions('userControl', ['saveUser', 'unReadUser']),
			...mapActions('irrigatorControl', [
				'irrigatorsFromChannel',
				'irrigatorsAssigned',
			]),

			async selectFromChannel(code) {
				const list = await this.irrigatorsFromChannel(code);
				this.irrigatorsToSend = this.irrigatorsToSend.concat(list);

				this.loadIrrigatorsSelected(this.irrigatorsToSend);
			},

			unselectFromChannel(code) {
				this.irrigatorsToSend = this.irrigatorsToSend.filter(
					item => item.channel != code
				);

				this.loadIrrigatorsSelected(this.irrigatorsToSend);
			},

			saveUserForm() {
				/* Se envia la referencia del area */
				if (
					['sectionm', 'watchman'].includes(
						this.user.association_area
					)
				)
					this.alertSaving = true;

				this.saveUser(this.toSend)
					.then(() => {
						this.dialog = false;
						this.user = {
							name: null,
							email: null,
							telephone: null,
							association_area: null,
							password: null,
						};
					})
					.finally(() => (this.alertSaving = false));
			},

			close() {
				this.unReadUser();
				this.dialog = false;
			},

			irrigatorsSelected(data) {
				this.irrigatorsToSend = data;
			},

			unselectIrrigator(data) {
				this.$refs['irrigator-list'].unselectIrrigator(data);
			},

			loadIrrigatorsSelected(data) {
				/* Si es null no se hace nada */
				if (data)
					this.$refs['irrigator-list'].loadIrrigatorSelected(data);
			},

			/* Metodos para los jefes de seccion */
			watchmansSelected(data) {
				this.watchmansToSend = data;
			},

			unselectWatchman(data) {
				this.$refs['watchman-list'].unselectWatchman(data);
			},

			loadWatchmanSelected(data) {
				/* Si es null no se hace nada */
				if (data)
					this.$refs['watchman-list'].loadWatchmanSelected(data);
			},
		},
	};
</script>
